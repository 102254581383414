<template>
<!-- 智慧化健身房指数排名 -->
    <div class="baixing darkBack">
        <div class="baixingnav">
            <div>智慧化健身房(区)预订人数排名</div>
            <div>
                <span class="anselecte" @click="selected">月</span>
                <span class="anselecte" @click="selected">年</span>
                <span class="anselecte" @click="selected">总</span>
            </div>
        </div>
        <div id="jianoption"></div>
        <ul class="figureul">
            <!-- <li>
                <img src="../../../assets/images/first.png" alt="">
            </li>
            <li>
                <img src="../../../assets/images/second.png" alt="">
            </li>
            <li>
                <img src="../../../assets/images/thirdly.png" alt="">
            </li> -->
            <li v-for="(item,index) in sport.data" :key="index">
                <img v-if="index==0" src="../../../assets/images/first.png" alt="">
                <img v-else-if="index==1" src="../../../assets/images/second.png" alt="">
                <img v-else-if="index==2" src="../../../assets/images/thirdly.png" alt="">
                <div v-else>{{index+1}}</div>
            </li>
            
        </ul>
    </div>
</template>

<script>
import bus from '/src/assets/js/bus.js'

export default {
    data () {
        return {
            sport: {
                data: [],
                number:[],
                max: 9,
                color1:"#169863",    // y轴刻度线的颜色
                color2:"#149663",    // 渐变初始颜色    
                color3:"#6BD871",    // 渐变末尾颜色
                color4:"#42BF6C",    // 柱状图上字体的颜色
            },
        }
    },
    methods: {
        selected(e){          // 点击切换样式
            // $('.selecte').class('background', "#000000")
            $('.anselecte').css({'background-color':'#00000000', 'color':'#838181'})
            e.target.style.background = "#7EA7E2";
            e.target.style.color = "#000000";
            // 切换柱状图表
            var switchover = e.target.innerText
            if(switchover == '月') this.getData("month");
            else if(switchover == '年') this.getData("year");
            else this.getData();
        },
        fitnes(id, number){
            let that = this;
            // let canye = this.$echarts.init(document.getElementById(id));
            let canye = this.$echarts.getInstanceByDom(document.getElementById(id))
            if (canye == null) { // 如果不存在，就进行初始化
                canye = this.$echarts.init(document.getElementById(id));
            }

            let option = {
                 xAxis: {
                    // max: 'dataMax',
                    show:true,
                    type: 'value',
                    "axisLine":{     //x轴坐标轴
                        "show":false,
                    },
                    axisLabel:{
                        show: false,
                    },
                    
                    axisTick: {		//x轴刻度线
                        show:false
                    },
                    splitLine: {            // 网格线的颜色
                        show: true,
                        lineStyle:{
                            color: ['#2D94D040'],
                            width: 1,
                            type: 'solid',
                        }
                　　}
                },
                // grid: {         // 间距
                //     left: '-24%',  
                //     right: '8%', 
                //     top:'2%', 
                //     bottom: '0%',  
                //     containLabel: true,
                // },
                grid: {         // 间距
                    left: '6%',  
                    right: '8%', 
                    top:'2%', 
                    bottom: '0%',  
                    containLabel: true,
                },
                yAxis: {
                    type: 'category',
                    data: number.data,
                    inverse: true,              // 上下反向
                    animationDuration: 300,
                    animationDurationUpdate: 300,
                    max: number.max,          // y轴显示显示最大的条数 
                    axisLine:{              // y轴左侧的样式
                        show:true,
                        lineStyle: {
                            color: number.color1,
                        }
                    },
                    splitLine:{show: false},
                    axisTick: {                 // y轴刻度线
                        show:false,
                        alignWithLabel: true,
                    },
                    splitArea : {show : false}, 
                    axisLabel: {               // y轴字体的颜色
                        // margin: 80,//刻度标签与轴线之间的距离
                        interval:0,//横轴信息全部显示 
                        textStyle: {
                            show:true,
                            fontFamily:'微软雅黑',
                            color: '#7EA7E2',
                            fontSize: '13',
                            // align: 'left'
                        },                           
                    },
                },
                series: [{
                    realtimeSort: true,
                    // name: 'X',
                    type: 'bar',
                    barWidth : '40%',              // 柱子的宽度
                    data: number.number,
                    itemStyle: {
                        normal: {
                            color: this.$echarts.graphic.LinearGradient(0, 0 , 1, 0, [{
                                offset: 0,
                                color: number.color2 // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: number.color3 // 100% 处的颜色
                            }], false)
                        }
                    },
                    label: {
                        formatter: "{c}",
                        show: true,
                        position: 'right',
                        valueAnimation: true,
                        color: number.color4,    // 柱状图上字体的颜色
                        fontSize:'0.13rem',
                    },
                }],
                legend: {
                    show: true
                },
                animationDuration: 0,
                animationDurationUpdate: 3000,
                animationEasing: 'linear',
                animationEasingUpdate: 'linear'
            };
            canye.setOption(option);

            // 图表自动缩放
            window.addEventListener("resize",function(){
              	canye.resize();
				$("#jianoption").css({width:"5.2rem",height:"3.4rem"})
				$("#ancent1").css({width:"5.2rem",height:"0.3rem"})
            });
        },
        // 获取指数排名
        getData(type){
            let that = this;
            let url;
            // console.log(value)
            if(type == undefined) url = window.appHost+`/api/v1/screen/Venue/rankingtop?street=${window.clickstreet}`;
            else url = window.appHost+`/api/v1/screen/Venue/rankingtop?street=${window.clickstreet}&type=${type}`;
            let result = window.ajaxRequest(url)
            // console.log(result)
            that.sport.data = [];
            that.sport.number = [];
            
            result.data.forEach((item,index)=>{
                that.sport.data.push(item.title);
                that.sport.number.push(item.num);
            })
            that.fitnes("jianoption", that.sport);

        }
    },
    mounted () {
        // this.fitnes("jianoption", this.sport);
        this.getData();
        bus.$on('now_street',res=>{
            this.getData();
        })
    },
    beforeDestroy(){
        bus.$off('now_street')
    }
}
</script>

<style scoped>
.baixing{
    width:5.94rem;
    height:4.20rem;
    font-size: 0.18rem;
    color:#fff;
    padding: 0.2rem;
    position: relative;
}
.baixingnav{
    display: flex;
    justify-content: space-between;
}


.anselecte{
    width:33%;
    text-align: center;
    height:0.22rem;
    line-height: 0.22rem;
    display: inline-block;
    font-size: 0.12rem;
    cursor: pointer;
    color:#838181;
}

.anselecte:nth-child(1){
    background-color:#7EA7E2;
    border-radius: 0.11rem 0rem 0rem 0.11rem;
    color:#000000;
}
.anselecte:nth-child(2){
    /* background:red; */
    border-left:0.01rem solid #838181;
    border-right:0.01rem solid #838181;
}
.anselecte:nth-child(3){
    /* background:red; */
    border-radius: 0rem 0.11rem 0.11rem 0rem;
}


.baixingnav>div:nth-child(2){
    display: flex;
    justify-content: space-around;
    width:1.05rem;
    height:0.22rem;
    border:0.01rem solid #838181;
    border-radius: 0.11rem;
}

#jianoption{
    width:5.50rem; 
    height:3.40rem;
    margin-top:0.2rem;
    /* border:1px solid red; */
}
.figureul{
    position: absolute;
    top:0.73rem;
    left:4%;
    font-size:0.12rem;
    color:#7EA7E2;
}
.figureul>li{
    width:0.20rem;
    line-height: 0.33rem;
    text-align: center;
}
/* .figureul > img{
    width: 0.08;
    height: 0.08;
}
.figureul > div{
    width: 0.08;
    height: 0.08;
} */
</style>